<template>
  <section class="page-container">
    <div>
      <div class="flex-col header text-left fw-500  color-dark">
        <span class="lh-150 fs-26">Racing Max</span>
        <span class="lh-150 fs-42 ">鴻朗保險</span>
      </div>
      <div class="flex-col content">
        <div class="text-left flex-col">
          <img
            class="img__confrim"
            src="../../assets/images/index/success.png"
            alt="">
          <div class="flex-col color-dark">
            <span class="fs-22 lh-150">Please pay attention to the number and wait for your policy
              application to be processed.</span>
            <span class="fs-32 lh-150">請留意櫃檯叫號，等候處理你的保單申請</span>
            <span class="color-primary fs-62">{{serial_number}}</span>
          </div>
        </div>
      </div>
    </div>
    <span class="mg-t-32 block">
      <v-button
        class="w-fill"
        @click="handleStart">請保留此頁面並歸還iPad予職員<br>Please keep this page and return the iPad to staff</v-button>
    </span>
  </section>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'success',
  computed:{
    ...mapState('quotation',['quotationData']),
    serial_number(){
      let {serial_number} = this.quotationData||{}
      return serial_number||0
    }
  },
  methods:{
    ...mapActions('quotation',['setQuoteInformationForm','setQuotationData']),
    handleStart(){
      this.setQuoteInformationForm(null)
      this.setQuotationData(null)
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped lang="scss">
.page-container{
  height: 100vh;
  position: relative;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 .block{
   display: block;
   padding: 0px 48px 39px 32px;
 }
  .header{
    padding:38px 0 0 32px;
  }
  .content{
    padding: 0px 48px 39px 32px;
    transform: translateY(25%);
    .img__confrim{
      width: 169px;
      height: 169px;
      margin-bottom: 8px;
    }
    @media screen and (max-width: 768px) {
      transform: translateY(25%);
    }
  }
  /deep/.el-button{
    width: 100% !important;
    height: auto;
  }
  .btn-label{
    padding: 4px 0;
  }
}
</style>
